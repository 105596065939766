<template>
    <v-data-table :headers="headers" :items="desserts" class="elevation-1">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title class="font-weight-black">Notas Fiscais Equipe Multiprofissional</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" rounded v-bind="attrs" v-on="on">
                            Carregar NF
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row >
                                    <v-col cols="12" sm="6">
                                        <v-text-field 
                                            v-model="editedItem.competencia" 
                                            label="Competência"
                                            placeholder="MM/AAAA"
                                            clearable
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field 
                                            v-model="editedItem.valornf" 
                                            label="Valor NF"
                                            placeholder="R$ 00,00"
                                            clearable
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-checkbox 
                                            v-model="editedItem.antecipacao" 
                                            :label="`Antecipação de PG: ${checkbox.toString()}`"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field 
                                            v-model="editedItem.novovalornf" 
                                            readonly
                                            label="Novo Valor NF"
                                            placeholder="R$ 00,00"
                                            clearable
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-file-input 
                                            v-model="editedItem.arquivo" 
                                            label="Arquivo NF"
                                            clearable
                                        ></v-file-input>
                                    </v-col>
                                    
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn rounded color="red" dark @click="close">
                                Cancelar
                            </v-btn>
                            <v-btn rounded color="green" dark @click="save">
                                Salvar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h4">Deseja apagar esse registro?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                                <v-btn rounded color="red" dark @click="closeDelete">Voltar</v-btn>
                                <v-btn rounded color="green" dark @click="deleteItemConfirm">Deletar</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Reset
            </v-btn>
        </template>
    </v-data-table>
</template>
<script>


export default {
    name: "nota_fiscal_equipe",
    data: () => ({
        dialog: false,
        dialogDelete: false,
        checkbox: true,
        files: [],
        headers: [
            { text: 'Competência', align: 'start', sortable: true, value: 'competencia'},
            { text: 'Valor NF', value: 'valornf' },
            { text: 'Antecipação de PG', value: 'antecipacao' },
            { text: 'Novo Valor NF', value: 'novovalornf' },
            { text: 'Arquivo', type: 'file', valeu: 'arquivo' },
            { text: 'Aceite', type: 'boleano', value: 'aceite' },
            { text: 'OBS', value: 'obs' },
            { text: 'Editar', value: 'actions', sortable: false },
        ],
        desserts: [],
        editedIndex: -1,
        editedItem: {
            competencia: '',
            valornf: '',
            checkbox: '',
            novovalornf: '',
            arquivo:[],
            aceite: [],
            obs:'',
        },
        defaultItem: {
            competencia: '',
            valornf: '',
            checkbox: '',
            novovalornf: '',
            arquivo: [],
            aceite: '',
            obs: '',
        },
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Carregar nova Nota Fiscal' : 'Editar Nota Fiscal'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.initialize()
    },

    methods: {
        initialize() {
            this.desserts = [
                {
                    competencia: 'mar/2022',
                    valornf: 'R$ 1.200,00',
                    checkbox: [],
                    novovalornf: 'R$ 1.200,00',
                    arquivo: [],
                    aceite: 'Sim',
                    obs: 'Sem Observação',

                },
                
            ]
        },

        editItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.desserts.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.desserts[this.editedIndex], this.editedItem)
            } else {
                this.desserts.push(this.editedItem)
            }
            this.close()
        },
    },
}
</script>